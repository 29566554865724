import {React, useEffect} from "react";
import $ from "jquery";
import "./style.css";
// router
import {
  Link
} from "react-router-dom";
import done from "../../asets/doneup.jpg";
import failed from "../../asets/sadup.jpg";

var Mouse=0;  //ci ma zariadenie mys
var zadanie=60;  //kolko min je v hre
var pocetMinMax=zadanie;
var gameOver=1;
var mineMap='';
var visMap='';
var countMap='';
var flagMap='';
var cc=0;
var sx=0;
var sy=0;
var dbx=-1;
var dby=-1;
var resX=20;  //rozlisenie hracej plochy X
var resY=20;  //rozlisenie hracej plochy Y

//detekcia ci ma zariadenie mys
$(window).bind('mousemove.hasMouse',function(){
  $(window).unbind('.hasMouse');
Mouse=1;
}).bind('touchstart.hasMouse',function(){
  $(window).unbind('.hasMouse');
Mouse=0;
});


const Intermediate=()=>{
  const divs=[];

  useEffect(() => {
    startGame();
  }, []);

//######## FUNKCIE #################


//zakladna funkcia pre spracovanie clicku na policko
function spracuj(event,co,force) {
 force = force || 0; //ak nie je force naplnene, daj default hodnotu
 if (gameOver>0) return;
 let x=0;let y=0;let tmppos=0;
 if (force==0) {
   x=parseInt(co.charAt(0)+co.charAt(1));	
   y=parseInt(co.charAt(2)+co.charAt(3));
 } else {
   x=sx; 
   y=sy;
   hidediv('#selection');	
 }
//mouse=0;
 if ((Mouse==1) || (force>0)) {
   //na PC
   if (force==0) {
     var e = event || window.event;  
     force=e.button;
     force++;
   } else {
     dbx=-1;dby=-1;
   }
   if (force==1) {
     //lave tlacitko mysi
     tmppos=getPos(x-1,y-1);
     if (mineMap.charAt(tmppos)=='1') {
       //alert('skok na minu');
       gameOver=1;
       showEnd(x,y);
       showMessagey();
     } else {
       odkri(x,y);
       isEnd();
     }			
   } else if (force==3)	{
     //alert(x+' '+y);
     cc++;
     tmppos=getPos(x-1,y-1);
     if (visMap.charAt(tmppos)=='0') {
       if (flagMap.charAt(tmppos)=='0') {
         flagMap=replaceChar(flagMap,tmppos,'1');
         drawSymbol(x,y,'flg');
       } else {
         flagMap=replaceChar(flagMap,tmppos,'0');
         drawSymbol(x,y,'buttup');
       }
     }
     isEnd();
     
   }
 } else {
   if (dbx!=-1) {
     //alert(x+' '+dbx)
     if ((dbx!=x) || (dby!=y)) drawSymbol(dbx,dby,'buttup');
     dbx=-1;dby=-1;
   }		
   sx=x;sy=y;
   tmppos=getPos(x-1,y-1);
   if (visMap.charAt(tmppos)=='0') {
     drawSymbol(x,y,'x');
     dbx=x;dby=y;
     showdiv('#selection',x-1,y-1);					
   } else {
     dbx=-1;dby=-1;
   };
 }
}

function drawSymbol(x,y,co) {
  x=x-1;y=y-1;
 //tmp=getPos(x,y);
 var picUrl='images/'+co+'.jpg';
 let xx=''+(x+1);let yy=''+(y+1);
 if (xx.length==1) xx='0'+xx;if (yy.length==1) yy='0'+yy;
 let tmpname=xx+yy;
 $('#'+tmpname).css('background-image', 'url(' + picUrl + ')');	
}

function odkri(x,y) {
 let tmp=getPos(x-1,y-1);
 let tmpzal=tmp;
 let co=countMap.charAt(tmp);	
 var picUrl='images/'+co+'.jpg';
 let xx=''+x;let yy=''+y;
 if (xx.length==1) xx='0'+xx;if (yy.length==1) yy='0'+yy;
 let tmpname=xx+yy;
 $('#'+tmpname).css('background-image', 'url(' + picUrl + ')');
   
 visMap=replaceChar(visMap,tmp,'1');
 flagMap=replaceChar(flagMap,tmpzal,'0'); //ak tam bol flag, nech zmizne

 if (co>0) return;
 
 //ideme do dat, prepnem real koordinaty na datove
 x=x-1;y=y-1;let tmppos=0;
 xx=x-1;yy=y-1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 xx=x;yy=y-1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 xx=x+1;yy=y-1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
       
 xx=x-1;yy=y;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 xx=x+1;yy=y;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 
 xx=x-1;yy=y+1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 xx=x;yy=y+1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 xx=x+1;yy=y+1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) odkri(xx+1,yy+1);}
 
}

function replaceChar(str,index,character) {
 let tmp=str.substr(0,index);
 tmp=tmp+character;
 tmp=tmp+str.substr(index+1);
 return tmp;
}
//valid je od 0 do 19
function isValidCoord(xx,yy) {
 if ((xx>=0) && (yy>=0) && (xx<resX) && (yy<resY)) {return true;} else {return false;}
}

function getPos(x,y) {
 return parseInt((resX*y)+x);
}

function startGame() {
 showClear();
 pocetMinMax=zadanie;
 //vygenerujem hracie pole
 mineMap='';visMap='';countMap='';flagMap='';
 let pocetMin=pocetMinMax;if (pocetMin<pocetMinMax) pocetMin=pocetMinMax;
 for (let i=0;i<400;i++) {
   mineMap=mineMap+'0';
   visMap=visMap+'0';
   countMap=countMap+'0';
   flagMap=flagMap+'0';
 }
 let tmpcount=0;
 for (let i=0;i<pocetMin;i++) {
   let x=Math.floor(Math.random()*resX);
   let y=Math.floor(Math.random()*resY);
   let tmp=getPos(x,y);
   if (mineMap.charAt(tmp)=='0') {
     mineMap=replaceChar(mineMap,tmp,'1');
     tmpcount++;
   }
 }
 pocetMinMax=tmpcount;
 let xx=0;let yy=0;let tmppos=0;
 for (let y=0;y<resY;y++) {
   for (let x=0;x<resX;x++) {
     let min=0;
     xx=x-1;yy=y-1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     xx=x;yy=y-1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     xx=x+1;yy=y-1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
           
     xx=x-1;yy=y;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     xx=x+1;yy=y;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     
     xx=x-1;yy=y+1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     xx=x;yy=y+1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     xx=x+1;yy=y+1;if (isValidCoord(xx,yy)) {tmppos=getPos(xx,yy);if (mineMap.charAt(tmppos)==1) min++;}
     if (min>0) {				
       let tmp=getPos(x,y);
       let tmpb=''+min;
       countMap=replaceChar(countMap,tmp,tmpb);
     }
   }	
 }
 //alert(countMap);
 //showEnd();
 gameOver=0;cc=0;
 //showMessage('Try explore area or find all mines');
 showMessage2('');	
 //$('#roleta').css('display', 'none');
}


function showEnd(lx,ly) {
 //zobrazi hraciu plochu vyriesenu
 for (let y=0;y<resY;y++) {
   for (let x=0;x<resX;x++) {	
     let tmppos=getPos(x,y);
     let co='';
     if (mineMap.charAt(tmppos)==1) {
       if ((x==(lx-1)) && (y==(ly-1))) {
         co='mineexpl';
       } else {
         co='mine';
       }
     } else {
       co=countMap.charAt(tmppos);
     }
     var picUrl='images/'+co+'.jpg';
     let xx=''+(x+1);let yy=''+(y+1);
     if (xx.length==1) xx='0'+xx;if (yy.length==1) yy='0'+yy;
     let tmpname=xx+yy;
     $('#'+tmpname).css('background-image', 'url(' + picUrl + ')');
   }
 }		
 gameOver=1;
}

function showClear() {
  $('#s0').css('background-image', 'url(images/0.jpg)');
  $('#s1').css('background-image', 'url(images/flg.jpg)');
 //zobrazi cistu hraciu plochu
 for (let y=0;y<resY;y++) {
   for (let x=0;x<resX;x++) {	
     var picUrl='images/buttup.jpg';
     let xx=''+(x+1);let yy=''+(y+1);
     if (xx.length==1) xx='0'+xx;if (yy.length==1) yy='0'+yy;
     let tmpname=xx+yy;
     $('#'+tmpname).css('background-image', 'url(' + picUrl + ')');
   }
 }	

}

function isEnd() {
 //kontrola ci je hra vyriesena
 let pass=0;let oznaceni=0;let slepych=0;let mn=0;let bl=0;
 for (let y=0;y<resY;y++) {
   for (let x=0;x<resX;x++) {				
     let tmppos=getPos(x,y);
     if (flagMap.charAt(tmppos)==1) oznaceni++;
     if (mineMap.charAt(tmppos)==1) mn++;
     if (visMap.charAt(tmppos)==1 ) bl++;
     if ((mineMap.charAt(tmppos)==1) && (flagMap.charAt(tmppos)==1)) {
       pass++;
     }
     if ((mineMap.charAt(tmppos)==0) && (visMap.charAt(tmppos)==0)) {
       slepych++;
     }
     
   }
 }
 //alert('pass'+pass+'-pocetmin:'+pocetMinMax+'-oznaceni:'+oznaceni+'-slepych:'+slepych);
 var tmp=(resX*resY)-mn;
 //alert(tmp+':'+bl);
 if (tmp==bl) {
   //alert('koniec 1');
   showMessagex();
   endGame();
 }
 
 //if ((pass==pocetMinMax) && (oznaceni==pocetMinMax) && (slepych==0)) {
  //ukonci hru aj ked oznaci vsetky vlajocky
  if ((pass==pocetMinMax) && (oznaceni==pocetMinMax)) {
   //alert('ok');
   showMessagex();
   //alert('koniec 2');
   endGame();
 }
 var tmp=(tmp-pocetMinMax); //100%
 var tmp2=(slepych/tmp);
 var chyba=parseInt(100-(tmp2*100));
 showMessage2('Progress: '+Math.abs(chyba)+'%');
 //alert(slepych);
}

function showMessagex() {
 $('#spravax').css({ opacity: 0 });
 $('#spravax').show();
 $('#spravax').animate({opacity: 0.85}, 1000);		
}

function showMessagey() {
  $('#spravay').css({ opacity: 0 });
  $('#spravay').show();
  $('#spravay').animate({opacity: 0.85}, 1000);		
 }
 

// function showMessage(message) {
//  $('#statusdiv').css({ opacity: 0 });
//  $('#statusdiv').show();
//  $('#statusdiv').html(message);
//  $('#statusdiv').animate({opacity: 1.0}, 500);		
// }


function showMessage2(message) {
 $('#msg2').css({ opacity: 0 });
 $('#msg2').show();
 $('#msg2').html(message);
 $('#msg2').animate({opacity: 1.0}, 500);		
}

function endGame() {
  if (gameOver>0) return;
  gameOver=1; //koniec hry
}

function showdiv(divname,x,y) {
  //zobrazim popup
  $(divname).css({ opacity: 0 });
  $(divname).css({ "display" : "block" });
  $(divname).animate({opacity: 1.0}, 100);	
}

function hidediv(divname) {
  $(divname).css({ opacity: 1 });
  $(divname).animate({opacity: 0}, 100);  
  $(divname).css({ "display" : "none" });  
}

function HraciePole() {
  // for (let i=0;i<cols;i++) {
  //   stlpce.push(<td className="headerdatagridtd" style={ props.style._header } key={props.header[i].key}><div>{props.header[i].text}</div></td>);
  // }
  let zzz=1;
  for (let y=1;y<=resY;y++) {
    for (let x=1;x<=resX;x++) {
        let a=''+x;if (a<10) a='0'+a;
        let b=''+y;if (b<10) b='0'+b;
        let tmpName=a+b;
        if (zzz!==y) {
          zzz=y;
          divs.push(<div key={tmpName+zzz} style={{clear:"both"}}/>);
        }
        divs.push(<div className="sudivex" id={tmpName} key={tmpName} onMouseUp={(e)=>spracuj(e,tmpName)}></div>);
    }				
  }

  return(
    <>
      {divs}
    </>
  )

}

//############ KONIEC FUNKCII ###############


  return (
    <div style={{width:"99%",}}>
      <br />
      {/* <div style={{width:"100%",border:"1px solid blue"}}><div className="hracie_pole" style={{overflow:"hidden"}} id="hraciepole"> */}
      <div>
        <div className="hracie_poleex" style={{overflow:"hidden"}} id="hraciepole">
          {HraciePole()}
        </div>
      </div>

      <div className="selector" id="selection">
				<div className="seldiv" onClick={(event)=>spracuj(event,'',1)} id="s0"></div>
				<div className="seldiv" onClick={(event)=>spracuj(event,'',3)} id="s1"></div>
			</div>	

      <div id="spravax" style={{border:"2px solid black",borderRadius:"10px",display:"none",position:"fixed",top:"35%",left:"0px",right:"0px",width:"350px",fontSize:"22px",background:"white",padding:"10px",opacity:"0.7",margin:"5% auto"}}>
        Congratulations, you finished the game<br /><br />
        {/* <button className="okbtn" onClick={}>OK</button> */}
        <img src={done} width="100"></img><br /><br />
        <Link to="/"style={{textDecoration:"none",border:"1px solid black"}} onClick={()=>{$('#msg2').hide();$('#statusdiv').hide();}} className="okbtn">OK</Link>
        <br />
      </div>

      <div id="spravay" style={{border:"2px solid black",borderRadius:"10px",display:"none",position:"fixed",top:"35%",left:"0px",right:"0px",width:"350px",fontSize:"22px",background:"white",padding:"10px",opacity:"0.7",margin:"5% auto"}}>
        You failed to solve puzzle<br /><br />
        <img src={failed} width="100"></img><br /><br />
        <Link to="/"style={{textDecoration:"none",border:"1px solid black"}} onClick={()=>{$('#msg2').hide();$('#statusdiv').hide();}} className="okbtn">OK</Link>
        <br />
      </div>


    </div>
  )
}

export default Intermediate;