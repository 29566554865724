import React from "react";
import help1 from '../../asets/help1.png';
import help2 from '../../asets/help2.png';
import help3 from '../../asets/help3.png';

const About=()=>{
  return(
    <div style={{border:"none","width":"80%",margin:"auto",fontSize:"20px"}}>      
      The goal is to clear mined area using your logic.<br /> The number on a tile
      tells you the number of mines around that tile, which may vary from 1 to 8.<br /><br />
      <b>1. Start with random click on the area until you get a similar view:</b> <br /><br />
      <img src={help1} width="300"/><br /><br />
      <b>2. Find all corners with number 1 and mark them with flag as there must be mine under the flag.</b><br /><br />
      <img src={help2} width="300"/><br /><br />
      <b>3. Continue marking obvious mines and clicking on tiles which must be empty until you solve whole puzzle.</b><br /><br />
      <img src={help3} width="300"/><br /><br />
      
    </div>
  )
}

export default About;