import React from "react";
// router
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import smile from "../../asets/smileup.jpg";

const Main=()=>{
  return(
    <div>
      {/* nadpis */}
      <h1 style={{"color":"#306010"}}>MineSweeper</h1><br />
      <img alt="logo" width={100} src={smile} /><br />
      <button className="okbtn" style={{margin:"10px"}} title='Beginner' ><Link to='/beginner' style={{textDecoration:"none",color:"black"}}>Beginner</Link></button><br />
      <button className="okbtn" style={{margin:"10px"}} title='Intermediate' ><Link to='/intermediate' style={{textDecoration:"none",color:"black"}}>Intermediate</Link></button><br />
      <button className="okbtn" style={{margin:"10px"}} title='Expert' ><Link to='/expert' style={{textDecoration:"none",color:"black"}}>Expert</Link></button><br /><br />
      <span style={{}}><Link to='/about' style={{"color":"#42b983",fontSize:"19px"}}>How to play MineSweeper?</Link></span>
    </div>
  )
}

export default Main;