import React, {useState, useRef} from 'react';
import InstallPWA from "./components/InstallPWA";
import './App.css';

import enPic from './asets/flags/en.png';
import skPic from './asets/flags/sk.png';
import czPic from './asets/flags/cz.png';
import dePic from './asets/flags/de.png';
import frPic from './asets/flags/fr.png';

import home from './asets/home1.png';

// router
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
  useNavigate,
  Link
} from "react-router-dom";
//routes zoznam
import Main from "./routes/Main";
import About from "./routes/About";
import Beginner from "./routes/Beginner";
import Intermediate from "./routes/Intermediate";
import Expert from "./routes/Expert";

//vypnutie kontextoveho menu na prave tlacitko
if (document.addEventListener) { // IE >= 9; other browsers
  document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
  }, false);
} else { // IE < 9
  document.attachEvent('oncontextmenu', function() {
      window.event.returnValue = false;
  });
}

function App() {

  return (
    <div className="App">
        <HashRouter>
          {/* horna lista */}
          <div style={{"width":"95%","height":"35px","padding":"5px","position":"relative","marginBottom":"2px"}}>
            <Link to='/'><img style={{position:"absolute","left":"0px","cursor":"pointer"}} width="40" title='Start' src={home} /></Link>
            <InstallPWA />
            <div style={{float:"left",paddingLeft:"100px"}}><center>
              {/* <div id="statusdiv" style={{display:"none"}}></div> */}
              <div id="msg2" style={{display:"none",paddingLeft:"10px",clear:"both"}}></div>              
              </center>
            </div>
            
          </div>



          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/beginner" element={<Beginner />} />
            <Route path="/intermediate" element={<Intermediate />} />
            <Route path="/expert" element={<Expert />} />
          </Routes>

          <br style={{clear:"both"}}/> 
        </HashRouter>
        <div style={{bottom:"0px",right:"0px",position:"fixed",cursor:"pointer"}}>
    			<a href="https://iceorc.com/" title='IceOrc 2022'><img src="https://iceorc.com/images/logo256.png" alt="iceorc" style={{width:"70px"}}/></a>
    		</div>

    </div>
  );
}

export default App;